import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Clock from "./Clock";
import { useSigner, useAccount, useNetwork } from "wagmi";
import { ethers, ContractFactory } from "ethers";
import WrapperBaseAbi from "../../contractAbi/WrapperBaseAbi.json";
import { useNavigate } from "react-router-dom";
import { WRAPPER_CONTRACT_ADDRESS } from "../config";

const Outer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 8px;
`;

export default function ColumnNew(props) {
    const [nfts, setNfts] = useState(props.nftList.slice(0,8));
    const navigate = useNavigate();
    const { connector: activeConnector, isConnected } = useAccount();
    const { chain, chains } = useNetwork();
    const { data: signer, isError, isLoading } = useSigner();
    const loadMore = () => {
        let nftState = nfts;
        let start = nftState.length
        let end = nftState.length+4
        setNfts([...nftState, ...(props.nftList.slice(start, end))])
    }

    const unWrap = async (e, item) => {
        e.preventDefault();
        try {
            if(isConnected == true) {
                const wrapperBase = new ethers.Contract(WRAPPER_CONTRACT_ADDRESS[chain.id], WrapperBaseAbi ,signer);
                const tx = await wrapperBase.unWrap(
                    item.type == "ERC-721" ? 3 : 4,
                    item.id.slice(0, 42),
                    item.tokenId,
                    false
                  );
                let reciept = await tx.wait();
                console.log(reciept);
            }
        } catch (error) {
            console.log("Erorr");
            console.log(error);
        }
    }
  return (
    <div className='row'>
        {props.nftList.map( (nft, index) => (
            <div key={index} className="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12 mb-4">  
                <div className="nft__item m-0">
                    { nft.unlockTime &&
                        <div className="de_countdown">
                            Time left for unwrap <Clock deadline={nft.unlockTime} />
                        </div>
                    }
                    <div className="nft__item_wrap" style={{height: `100%`, cursor: "pointer"}} onClick={() => {navigate(`/detail/${nft.id.slice(0, 42)}/${nft.tokenId}`)} }>
                      <Outer>
                        <span>
                            <img src="./img/carousel/crs-1.jpg" className="lazy nft__item_preview" alt=""/>
                        </span>
                      </Outer>
                    </div>
                    <div className="nft__item_info">
                        <span style={{cursor: "pointer"}} onClick={() => {navigate(`/detail/${nft.id.slice(0, 42)}/${nft.tokenId}`)} }>
                            <h4 style={{fontWeight:"bold", fontSize:"18px"}}>Wrapped NFT</h4>
                            <div className="small-border" style={{alignItems:"left", marginLeft: "0px"}}></div>
                        </span>
                        <div className="nft__item_action">
                            <span onClick={e=>{unWrap(e, nft)}}>Unwrap</span>
                        </div>
                        <div className="nft__item_price" style={{cursor: "pointer"}} onClick={() => {navigate(`/detail/${nft.id.slice(0, 42)}/${nft.tokenId}`)} }>
                            <span onClick={()=> window.open(nft.nftLink, "_self")}>
                                <p>WNFT Address: {nft.id.slice(0, 8) + "..." + nft.id.slice(38, 42)}</p>
                                <p>WNFT Token ID: {nft.tokenId}</p>
                                <p>WNFT Type: {nft.type}</p>
                                <p>WNFT Amount: {nft.amount}</p>
                                <p>NFT Address: {nft.inAsset.slice(0, 8) + "..." + nft.inAsset.slice(38, 42)}</p>
                                <p>NFT Token ID: {nft.inTokenId}</p>
                            </span>
                        </div>
                    </div> 
                </div>
            </div>  
        ))}
        { props.nftList.length !== props.nftList.length &&
            <div className='col-lg-12'>
                <div className="spacer-single"></div>
                <span onClick={() => loadMore()} className="btn-main lead m-auto">Load More</span>
            </div>
        }
    </div>              
    );
}