import React from "react";
// import { Router, Location, Redirect } from '@reach/router';
import ReactDOM from "react-dom/client";
import { BrowserRouter, HashRouter, Routes, Route } from "react-router-dom";
import ScrollToTopBtn from "./menu/ScrollToTop";
import Header from "./menu/header";
import Home2 from "./pages/home2";
import Wallet from "./pages/wallet";
import Wrap from "./pages/wrap";
import Explore from "./pages/explore";
import Detail from "./pages/detail";

import ElegantIcons from "./pages/elegantIcons";
import FontAwesomeIcons from "./pages/fontAwesomeIcons";
import Progressbar from "./pages/progressbar";
import Tabs from "./pages/tabs";
import {
  EthereumClient,
  modalConnectors,
  walletConnectProvider,
} from "@web3modal/ethereum";
import { Web3Modal } from "@web3modal/react";
import { createGlobalStyle } from "styled-components";
import { configureChains, createClient, WagmiConfig } from "wagmi";
import { metis, goerli } from "wagmi/chains";


import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink,
  from,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";

const chains = [metis, goerli];

const { provider } = configureChains(chains, [
  walletConnectProvider({ projectId: "76901fe57413c3022ef214f601d3edb9" }),
]);
const wagmiClient = createClient({
  autoConnect: true,
  connectors: modalConnectors({
    projectId: "76901fe57413c3022ef214f601d3edb9",
    version: "1", // or "2"
    appName: "web3Modal",
    chains,
  }),
  provider,
});

const ethereumClient = new EthereumClient(wagmiClient, chains);

const GlobalStyles = createGlobalStyle`
  :root {
    scroll-behavior: unset;
  }
`;

const errorLink = onError(({ graphqlErrors, networkError }) => {
  if (graphqlErrors) {
    graphqlErrors.map(({ message, location, path }) => {
      alert(`Graphql error ${message}`);
    });
  }
});

const link = from([
  errorLink,
  new HttpLink({ uri: "https://nftmb.nftapparel.com.au/graphql" }),
]);

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: link,
});

const app = () => (
  <div className="wraper">
    <ApolloProvider client={client}>
      <WagmiConfig client={wagmiClient}>
          <GlobalStyles />
          <HashRouter>
            <Header />
            <Routes>
              <Route path="/">
                <Route index element={<Home2 />} />
                <Route path="/home2" element={<Home2 />} />
                <Route path="explore" element={<Explore />} />
                <Route path="wallet" element={<Wallet />} />
                <Route path="wrap" element={<Wrap />} />
                <Route path="elegantIcons" element={<ElegantIcons />} />
                <Route path="etlineIcons" element={<ElegantIcons />} />
                <Route path="fontAwesomeIcons" element={<FontAwesomeIcons />} />
                <Route path="progressbar" element={<Progressbar />} />
                <Route path="tabs" element={<Tabs />} />
                <Route path="detail/:contract/:id" element={<Detail />} />
              </Route>
            </Routes>
          </HashRouter>
          <ScrollToTopBtn />
      </WagmiConfig>
      <Web3Modal
        projectId="76901fe57413c3022ef214f601d3edb9"
        ethereumClient={ethereumClient}
      />
    </ApolloProvider>
  </div>
);
export default app;
