import React from "react";
import SliderMainZero from "../components/SliderMainZero";
import FeatureBox from "../components/FeatureBox";
import CarouselCollection from "../components/CarouselCollection";
import CarouselNew from "../components/ColumnNew";
import AuthorList from "../components/authorList";
import Footer from "../components/footer";
import Reveal from "react-awesome-reveal";
import { keyframes } from "@emotion/react";
import { Link } from "react-router-dom";

const fadeInUp = keyframes`
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  
`;

const hometwo = () => (
  <div>
    <section className="jumbotron no-bg bg-gray">
      <SliderMainZero />
    </section>

    <section className="container no-top" style={{ marginTop: "50px" }}>
      <div className="text-center">
        <h1>A new way to realize value on Andromeda</h1>
        <div className="small-border"></div>
      </div>
      <div className="text-center">
        <h4>
          Wrapped NFTs is the next evolution in fiat on-ramping and asset
          management.
          <br></br>
          <br></br>As users trade wNFTs on the secondary market, the collateral
          contract collects royalties. Long-term asset holders are rewarded with
          a share of unwrap fees!
          <br></br>
          <br></br>As a public utility protocol, any project or holder can lock
          collateral inside of their NFTs, giving NFTs real collateral value
          beyond speculation.
        </h4>
      </div>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <div className="text-center">
        <h2>Get Started</h2>
        <div className="small-border"></div>
      </div>

      <div
        className="row"
        style={{
          alignItems: "center",
          textAlign: "center",
          justifyContent: "center",
        }}
      >
        <div
          className="row"
          style={{
            alignItems: "center",
            textAlign: "center",
            justifyContent: "center",
          }}
        >
          <div
            className="col-lg-3 col-md-6 mb-3"
            style={{ alignItems: "center", textAlign: "center" }}
          >
            <div className="feature-box f-boxed style-3">
              <Reveal
                className="onStep"
                keyframes={fadeInUp}
                delay={0}
                duration={600}
                triggerOnce
              >
                <img width="15%" src="./img/connect_wallet.png"></img>
                <br></br>
              </Reveal>
              <div className="text">
                <Reveal
                  className="onStep"
                  keyframes={fadeInUp}
                  delay={100}
                  duration={600}
                  triggerOnce
                >
                  <h4 className="">NFT Address</h4>
                </Reveal>
                <Reveal
                  className="onStep"
                  keyframes={fadeInUp}
                  delay={200}
                  duration={600}
                  triggerOnce
                >
                  <p className="">
                    Copy and paste the NFT token contract you wish to wrap!
                  </p>
                </Reveal>
              </div>
            </div>
          </div>

          <div
            className="col-lg-3 col-md-6 mb-3"
            style={{ alignItems: "center", textAlign: "center" }}
          >
            <div className="feature-box f-boxed style-3">
              <Reveal
                className="onStep"
                keyframes={fadeInUp}
                delay={0}
                duration={600}
                triggerOnce
              >
                <img width="15%" src="./img/create_your_collection.png"></img>
                <br></br>
              </Reveal>
              <div className="text">
                <Reveal
                  className="onStep"
                  keyframes={fadeInUp}
                  delay={100}
                  duration={600}
                  triggerOnce
                >
                  <h4 className="">Setup Collateral</h4>
                </Reveal>
                <Reveal
                  className="onStep"
                  keyframes={fadeInUp}
                  delay={200}
                  duration={600}
                  triggerOnce
                >
                  <p className="">
                    Select the amount of collateral and duration of your lock!
                  </p>
                </Reveal>
              </div>
            </div>
          </div>

          <div
            className="col-lg-3 col-md-6 mb-3"
            style={{ alignItems: "center", textAlign: "center" }}
          >
            <div className="feature-box f-boxed style-3">
              <Reveal
                className="onStep"
                keyframes={fadeInUp}
                delay={0}
                duration={600}
                triggerOnce
              >
                <img width="15%" src="./img/add_your_nft.png"></img>
                <br></br>
              </Reveal>
              <div className="text">
                <Reveal
                  className="onStep"
                  keyframes={fadeInUp}
                  delay={100}
                  duration={600}
                  triggerOnce
                >
                  <h4 className="">Earn Yields</h4>
                </Reveal>
                <Reveal
                  className="onStep"
                  keyframes={fadeInUp}
                  delay={200}
                  duration={600}
                  triggerOnce
                >
                  <p className="">
                    Watch your collateral grow with secondary market royalties
                    and fees.{" "}
                  </p>
                </Reveal>
              </div>
            </div>
          </div>
          <div
            className="col-lg-3 col-md-6 mb-3"
            style={{ alignItems: "center", textAlign: "center" }}
          >
            <div className="feature-box f-boxed style-3">
              <Reveal
                className="onStep"
                keyframes={fadeInUp}
                delay={0}
                duration={600}
                triggerOnce
              >
                <img width="15%" src="./img/list_for_sale.png"></img>
                <br></br>
              </Reveal>
              <div className="text">
                <Reveal
                  className="onStep"
                  keyframes={fadeInUp}
                  delay={100}
                  duration={600}
                  triggerOnce
                >
                  <h4 className="">Safe Unwrap</h4>
                </Reveal>
                <Reveal
                  className="onStep"
                  keyframes={fadeInUp}
                  delay={200}
                  duration={600}
                  triggerOnce
                >
                  <p className="">
                    Unwrap your NFT to redeem the underlying collateral!
                  </p>
                </Reveal>
              </div>
            </div>
          </div>
          <Reveal
            className="onStep"
            keyframes={fadeInUp}
            delay={800}
            duration={900}
            triggerOnce
            style={{ marginTop: "30px" }}
          >
            <div className="row centered">
            <Link
              to="/wrap"
              className="btn-main lead m-10-hor"
              style={{ backgroundColor: "#9999FF" }}
            >
              WRAP YOUR NFT
            </Link>
            <Link
              to="/explore"
              className="btn-main lead m-10-hor"
              style={{ backgroundColor: "#9999FF" }}
            >
              Explore Wrapped NFT
            </Link>
            </div>
          </Reveal>
        </div>
      </div>
    </section>
    <Footer />
  </div>
);
export default hometwo;
