import { formatUnits } from "ethers/lib/utils";
import React from "react";
import { Tabs, Tab, Table } from "react-bootstrap";
import { useContractReads, erc20ABI } from "wagmi";

const Collateral = (props) => {
    const decimalsContract = props.collateral.map((item, index) => {
        return {
            address: item.asset.contractAddress,
            abi: erc20ABI,
            functionName: 'decimals',
            args: []
        }
    });
    const symbolContract = props.collateral.map((item, index) => {
        return {
            address: item.asset.contractAddress,
            abi: erc20ABI,
            functionName: 'symbol',
            args: []
        }
    });
    const { data: decimalData, isError, isLoading } = useContractReads({
        contracts: decimalsContract,
    });
    const { data: symbolData, isError: symbolError, isLoading: symbolLoading } = useContractReads({
        contracts: symbolContract,
    });
  return (
    <Table striped bordered hover variant="dark">
      <thead>
        <tr>
          <th>#</th>
          <th>Token Symbol</th>
          <th>Contract Address</th>
          <th>Token Amount</th>
        </tr>
      </thead>
      <tbody>
        {symbolData && decimalData && props.collateral.map((item, index) => {
          return (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{symbolData[index]}</td>
              <td>{item?.asset?.contractAddress.slice(0, 8) + "..." + item?.asset?.contractAddress.slice(38, 42)}</td>
              <td>{formatUnits(item?.amount?.toString(), decimalData[index])}</td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default Collateral;
