import React from 'react';
import Reveal from 'react-awesome-reveal';
import { keyframes } from "@emotion/react";
import { Link } from "react-router-dom";

const fadeInUp = keyframes`
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const slidermainzero= () => (
  <div className="container">
  <div className="row align-items-center">
        <div className="col-md-6">
            <div className="spacer-single"></div>
            <div className="spacer-10"></div>
            <Reveal className='onStep' keyframes={fadeInUp} delay={300} duration={600} triggerOnce>
            <h1 className="" style={{color:"#9999FF"}}>Peak NFT Wrapping Service on Andromeda!</h1>
            </Reveal>
            <br></br>
            <Reveal className='onStep' keyframes={fadeInUp} delay={600} duration={600} triggerOnce>
            <p className=" lead">
            Time-lock your collateral by wrapping your NFTs. 
            <br></br>Watch your collateral grow from secondary market royalties and your share of unwrap fees!
            <br></br><br></br>
            Wrapped NFTs are the perfect way to:
<br></br>- Secure and transfer your assets in a batch. 
<br></br>- Bootstrap and protect liquidity. 
<br></br>- Provide underlying collateral to your NFT.
<br></br>- Incentivize your project's liquidity.

            </p>
            </Reveal>
            <div className="spacer-10"></div>
            <br></br>
            <Reveal className='onStep' keyframes={fadeInUp} delay={800} duration={900} triggerOnce>
            <div className='row'>
              <Link to="/wrap" className="btn-main" style={{backgroundColor:"#9999FF"}}>Wrap Your NFT</Link>
              <Link to="/explore" className="btn-main" style={{backgroundColor:"#9999FF", marginLeft:"5%"}}>Explore Wrapped NFT</Link>
            </div>
            <div className="mb-sm-30"></div>
            </Reveal>
            <br></br><br></br>
            
        </div>
        <div className="col-md-6 xs-hide">
          <Reveal className='onStep' keyframes={fadeIn} delay={900} duration={1500} triggerOnce>
            <img src="./img/misc/nft.png" width="400px" style={{marginLeft:"100px"}} className="lazy img-fluid" alt="" />
          </Reveal>
        </div>
    </div>
  </div>
);
export default slidermainzero;