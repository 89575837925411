import React, { useEffect, useState } from "react";
import Select from "react-select";
import ColumnNew from "../components/ColumnNew";
import Footer from "../components/footer";
import { createGlobalStyle } from "styled-components";
import { useAccount, useNetwork } from "wagmi";
import { ApolloClient, InMemoryCache, gql } from "@apollo/client";
import "dotenv/config";
import { GRAPH_URL } from "../config";

const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.sticky.white {
    background: #403f83;
    border-bottom: solid 1px #403f83;
  }
  header#myHeader.navbar .search #quick_search{
    color: #fff;
    background: rgba(255, 255, 255, .1);
  }
  header#myHeader.navbar.white .btn, .navbar.white a, .navbar.sticky.white a{
    color: #fff;
  }
  header#myHeader .dropdown-toggle::after{
    color: rgba(255, 255, 255, .5);;
  }
  header#myHeader .logo .d-block{
    display: none !important;
  }
  header#myHeader .logo .d-none{
    display: block !important;
  }
  @media only screen and (max-width: 1199px) {
    .navbar{
      background: #403f83;
    }
    .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
      background: #fff;
    }
    .item-dropdown .dropdown a{
      color: #fff !important;
    }
  }
`;

const tokensQuery = `
    query($owner: String) {
      ownerships(where: {owner: $owner, amount_gt: 0}) {
        id
        type
        owner
        amount
        tokenId
        unlockTime
        inAsset
        inTokenId
      }
    }
`;

export default function Explore() {
  const { address, connector: activeConnector, isConnected } = useAccount();
  const [nftList, setNftList] = useState([]);
  const { chain, chains } = useNetwork();

  const client = new ApolloClient({
    uri: GRAPH_URL[chain ? chain.id : 5],
    cache: new InMemoryCache(),
  });

  useEffect(() => {
    async function fetchData() {
      const res = await client.query({
        query: gql(tokensQuery),
        variables: {
          owner: address?.toLowerCase(),
        },
      });
      if (res.data && res.data.ownerships) {
        setNftList(res.data.ownerships);
      }
    }
    fetchData();
  }, [address]);

  return (
    <div>
      <GlobalStyles />

      <section className='jumbotron breadcumb no-bg' style={{backgroundImage: `url(${'./img/background/subheader.jpg'})`}}>
        <div className='mainbreadcumb'>
          <div className='container'>
            <div className='row m-10-hor'>
              <div className='col-12 text-center'>
                <h1>Your Wrapped NFTs</h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="container" style={{ minHeight: "50vh" }}>
        <div className="row">
          <div className="col-lg-12">
            <div className="items_filter"></div>
          </div>
        </div>
        <ColumnNew nftList={nftList} />
      </section>

      <Footer />
    </div>
  );
}
