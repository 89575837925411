import React, { useState, useEffect, useRef } from "react";
import Footer from "../components/footer";
import { createGlobalStyle } from "styled-components";
import { useSigner, useProvider } from "wagmi";
import { ethers, ContractFactory } from "ethers";
import WrapperBaseAbi from "../../contractAbi/WrapperBaseAbi.json";
import ERC721Abi from "../../contractAbi/ERC721Abi.json";
import ERC1155Abi from "../../contractAbi/ERC1155Abi.json";
import ERC20Abi from "../../contractAbi/ERC20Abi.json";
import { useMutation } from "@apollo/client";
import { INITIATE_LOGIN_MUTATION } from "../../GraphQL/initiate-login";
import { LOGIN_MUTATION } from "../../GraphQL/login";
import { useAccount, useNetwork } from "wagmi";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import Select from "react-dropdown-select";
import "dotenv/config";
import { WRAPPER_CONTRACT_ADDRESS } from "../config";

const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.sticky.white {
    background: #fff;
    border-bottom: solid 1px #fff;
  }
  header#myHeader.navbar .search #quick_search{
    color: #fff;
    background: rgba(255, 255, 255, .1);
  }
  header#myHeader.navbar.white .btn, .navbar.white a, .navbar.sticky.white a{
    color: #fff;
  }
  header#myHeader .dropdown-toggle::after{
    color: rgba(255, 255, 255, .5);
  }
  header#myHeader .logo .d-block{
    display: none !important;
  }
  header#myHeader .logo .d-none{
    display: block !important;
  }
  @media only screen and (max-width: 1199px) {
    .navbar{
      background: #fff;
    }
    .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
      background: #fff;
    }
    .item-dropdown .dropdown a{
      color: #fff !important;
    }
  }
`;

export default function Wrap() {
  const [inputFields, setInputFields] = useState([
    {
      token_id: "",
      token_value: "",
    },
  ]);
  const { chain, chains } = useNetwork();
  const addInputField = () => {
    setInputFields([
      ...inputFields,
      {
        token_id: "",
        token_value: "",
      },
    ]);
  };
  const removeInputFields = (index) => {
    const rows = [...inputFields];
    rows.splice(index, 1);
    setInputFields(rows);
  };
  const handleCollateralChange = (index, evnt) => {
    const { name, value } = evnt.target;
    const list = [...inputFields];
    list[index][name] = value;
    setInputFields(list);
  };

  const [customInputFields, setCustomInputFields] = useState([
    {
      custom_token_id: "",
      custom_token_value: "",
    },
  ]);

  const addCustomInputField = () => {
    setCustomInputFields([
      ...customInputFields,
      {
        custom_token_id: "",
        custom_token_value: "",
      },
    ]);
  };
  const removeCustomInputFields = (index) => {
    const rows = [...customInputFields];
    rows.splice(index, 1);
    setCustomInputFields(rows);
  };
  const handleCustomCollateralChange = (index, evnt) => {
    const { name, value } = evnt.target;
    const list = [...customInputFields];
    list[index][name] = value;
    setCustomInputFields(list);
  };
  const [lockDate, setLockDate] = useState(new Date());
  const [submitStatus, setSubmitStatus] = useState("Wrap");
  const [errorStatus, setErrorStatus] = useState("");
  const [showTextBox, setShowTextBox] = useState(false);
  const [importCollection, setImportCollection] = useState(false);
  const [initiateLogin, { data: messageToSign, error }] = useMutation(
    INITIATE_LOGIN_MUTATION
  );
  const [login, { data: KEY, error: error2 }] = useMutation(LOGIN_MUTATION);
  const [isActive, setIsActive] = useState(true);

  const formData = useRef({});

  const { connector: activeConnector, isConnected } = useAccount();
  const { data: signer, isError, isLoading } = useSigner();
  const provider = useProvider();

  useEffect(() => {
    async function fetch() {
      try {
        if (messageToSign) {
          const signature = await signer.signMessage(
            messageToSign["initiateLogin"]
          );
          let address = await signer.getAddress();
          console.log("messageToSign", messageToSign);
          const data = await login({
            variables: {
              user: address,
              challenge: messageToSign["initiateLogin"],
              signature,
            },
          });
        }
      } catch (error) {
        if (error.code === 4001) {
          setImportCollection(false);
          console.log("User denied message signature.");
        }

        console.error(error);
      }
    }
    fetch();
  }, [messageToSign]);

  async function submitData(e) {
    try {
      e.preventDefault();
      if (!isActive) return;
      if (!isConnected) {
        throw Error("Please connect your wallet");
      }
      setIsActive(false);
      setErrorStatus("");

      if (importCollection && !KEY) {
        throw Error("Please sign the message to import collection");
      }
      setSubmitStatus("Checking NFT Collection for Wrap...");
      const ERC165Abi = [
        {
          inputs: [
            {
              internalType: "bytes4",
              name: "interfaceId",
              type: "bytes4",
            },
          ],
          name: "supportsInterface",
          outputs: [
            {
              internalType: "bool",
              name: "",
              type: "bool",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
      ];


      setSubmitStatus("Approving NFT for Wrap...");
      const collection = new ethers.Contract(formData.current.nft_address, ERC165Abi, signer);
      
      let nftType = "";
      
      const erc721 = await collection.supportsInterface("0x80ac58cd");
      if(erc721 == false) {
        const erc1155 = await collection.supportsInterface("0xd9b67a26");
        if(erc1155 == false) {
          setIsActive(true);
          setSubmitStatus("Use proper nft collection address");
          return;
        } else {
          nftType = "ERC-1155";
        }
      } else {
        nftType = "ERC-721";
      }

      const nftCollection = new ethers.Contract(formData.current.nft_address, nftType == "ERC-1155" ? ERC1155Abi : ERC721Abi, signer);
      if(nftType == "ERC-1155") {
        const tx = await nftCollection.setApprovalForAll(WRAPPER_CONTRACT_ADDRESS[chain ? chain.id : 5], true);
        await tx.wait();
      } else {
        const tx = await nftCollection.approve(WRAPPER_CONTRACT_ADDRESS[chain ? chain.id : 5], formData.current.token_id);
        await tx.wait();
      }
      const wrapperBase = new ethers.Contract(WRAPPER_CONTRACT_ADDRESS[chain ? chain.id : 5], WrapperBaseAbi ,signer);
      const _inData = {
        inAsset : {
          asset: {
            assetType: nftType == "ERC-1155" ? 4 : 3,
            contractAddress: formData.current.nft_address
          },
          tokenId: formData.current.token_id,
          amount: 1
        },
        unWrapDestination: ethers.constants.AddressZero,
        unlockTime: Math.floor(new Date(lockDate).getTime() / 1000),
        outType: formData.current.nft_standard == "ERC-1155" ? 4 : 3,
        outBalance: formData.current.nft_standard == "ERC-1155" ? formData.current.nft_output_amount : 1,
      }
      const wallet = await signer.getAddress();
      let _collateral = [];
      setSubmitStatus("Approving Collateral Tokens...");
      for(let i = 0 ; i < inputFields.length ; i ++) {
        if(inputFields[i].token_id != "") {
          _collateral.push({
            asset: {
              assetType: 2,
              contractAddress: inputFields[i].token_id
            }, 
            tokenId: 0,
            amount: inputFields[i].token_value
          })
        }
      } 
      for(let i = 0 ; i < customInputFields.length ; i ++) {
        if(customInputFields[i].custom_token_id != "") {
          _collateral.push({
            asset: {
              assetType: 2,
              contractAddress: customInputFields[i].custom_token_id
            }, 
            tokenId: 0,
            amount: customInputFields[i].custom_token_value
          })
        }
      }
      for(let i = 0 ; i < _collateral.length ; i ++) {
        const token = new ethers.Contract(_collateral[i].asset.contractAddress, ERC20Abi, signer);
        const decimals = await token.decimals();
        _collateral[i].amount = ethers.utils.parseUnits(_collateral[i].amount, decimals);
        const tx = await token.approve(WRAPPER_CONTRACT_ADDRESS[chain ? chain.id : 5], _collateral[i].amount);
        await tx.wait();
      }
      setSubmitStatus("Waiting for NFT Wrapping...");
      // console.log(await wrapperBase)
      const tx = await wrapperBase.wrap(
        _inData,
        _collateral,
        wallet
      );
      let reciept = await tx.wait();
      setIsActive(true);
      setSubmitStatus("NFT Wrapped!");
      console.log(reciept);
      alert(
        "Congratulations! Your NFT has been wrapped. Check your wrapped nft from the NFT Listing page 😃"
      );
    } catch (err) {
      setSubmitStatus("Something went wrong:(");
      console.log(err);
      setIsActive(true);
      if (err && err.message && err.message != "") {
        setErrorStatus(err.message.toString());
      }
    }
  }

  
  
  
  
  function inputEvent(e) {
    console.log(e.target.name, e.target.value);
    if(e.target.value=="ERC-1155") {
      setShowTextBox(true);
    }
    if(e.target.value=="ERC-721") {
      setShowTextBox(false);
    }
    formData.current = { ...formData.current, [e.target.name]: e.target.value };
  }

  

  return (
    <div style={{backgroundColor:"#081524"}}>
      <GlobalStyles />

      <section className="jumbotron breadcumb no-bg">
        <div className="mainbreadcumb">
          <div className="container">
            <div className="row m-10-hor">
              <div className="col-12">
                <h1 className="text-center" style={{ color: "#14141f" }}>
                  Wrap Your NFTs
                </h1>
                <div className="small-border"></div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="container">
        <div className="row" style={{ justifyContent: "center" }}>
          <div
            className="col-lg-7 offset-lg-1 mb-5"
            style={{ marginTop: "-200px", marginLeft: "0%" }}
          >
            <form
              id="form-create-item"
              className="form-border"
              action="#"
              onSubmit={(e) => submitData(e)}
            >
              <div className="field-set">
                <div className="spacer-single"></div>
                <div
                  style={{ backgroundColor: "#142336"}}
                >
                  <div
                    style={{
                      paddingLeft: "10%",
                      paddingRight: "10%",
                      textAlign: "center",
                    }}
                  >
                    <div className="spacer-10"></div>
                    <div className="spacer-10"></div>
                    <h3>NFT Details</h3>
                    <div
                      className="small-border"
                      style={{ alignItems: "left" }}
                    ></div>

                    <div className="spacer-10"></div>
                    <h5>NFT Address</h5>

                    <input
                      type="text"
                      name="nft_address"
                      id="nft_address"
                      className="form-control"
                      placeholder="Paste your NFT address here"
                      onChange={(e) => inputEvent(e)}
                    />

                    <div className="spacer-10"></div>

                    <h5>Token ID</h5>
                    <input
                      type="text"
                      name="token_id"
                      id="token_id"
                      className="form-control"
                      placeholder="99 999"
                      onChange={(e) => inputEvent(e)}
                    />

                    <div className="spacer-10"></div>

                    <h5>wNFT Standard</h5>
                    <p style={{ fontSize: "12px" }}>
                      ERC-721 and ERC-1155 are the contract standards on
                      Andromeda.
                    </p>
                    <input
                      type="radio"
                      name="nft_standard"
                      id="nft_standard_721"
                      value="ERC-721"
                      onChange={(e) => inputEvent(e)}
                    />
                    <label htmlFor="nft_standard_721">
                      <span
                        style={{
                          color: "white",
                          fontSize: "18px",
                          verticalAlign: "center",
                        }}
                      >
                        &nbsp;ERC-721&nbsp;&nbsp;&nbsp;
                      </span>
                    </label>
                    <input
                      type="radio"
                      name="nft_standard"
                      id="nft_standard_1155"
                      value="ERC-1155"
                      onChange={(e) => inputEvent(e)}
                    />
                    
                    <label htmlFor="nft_standard_1155">
                      <span
                        style={{
                          color: "white",
                          fontSize: "18px",
                          verticalAlign: "center",
                        }}
                      >
                        &nbsp;ERC-1155
                      </span>
                    </label>
                    {showTextBox && <input placeholder="Enter the fractions" name="nft_output_amount" style={{marginLeft:"2%"}} onChange={(e) => inputEvent(e)} />}
                    <div className="spacer-10"></div>
                    <div className="spacer-10"></div>
                  </div>
                </div>
                <br></br>
                <br></br>
                <br></br>
                <div
                  style={{ backgroundColor: "#142336"}}
                >
                  <div
                    style={{
                      paddingLeft: "10%",
                      paddingRight: "10%",
                      textAlign: "center",
                    }}
                  >
                    <div className="spacer-10"></div>
                    <div className="spacer-10"></div>
                    <h3>Collateral</h3>
                    <div
                      className="small-border"
                      style={{ alignItems: "left" }}
                    ></div>
                    <p>
                      You can add assets to collateral of your wrapped NFT. Use
                      assets from the selected list
                    </p>
                    <div className="spacer-10"></div>

                    <div className="spacer-10"></div>

                    <h4>Supported Collateral Added</h4>
                    {inputFields.map((data, index) => {
                      const { token_id, token_value } = data;
                      return (
                        <div
                          className="row my-3"
                          style={{ width: "150%" }}
                          key={index}
                        >
                          <div className="col">
                            <div className="form-group">
                              <select
                                onChange={(evnt) =>
                                  handleCollateralChange(index, evnt)
                                }
                                value={token_id}
                                name="token_id"
                                className="form-control"
                                placeholder="Collateral To Be Added"
                              >
                                <option value=""> Select a token</option>
                                <option value="0xDeadDeAddeAddEAddeadDEaDDEAdDeaDDeAD0000">METIS</option>
                                <option value="0x1F5550A0F5F659E07506088A7919A88DfF37218f">PEAK</option>
                                <option value="0x259EF6776648500D7F1A8aBA3651E38b1121e65e">PRO</option>
                                <option value="0x420000000000000000000000000000000000000A">WETH</option>
                                <option value="0xEA32A96608495e54156Ae48931A7c20f0dcc1a21">USDC</option>
                                <option value="0xbB06DCA3AE6887fAbF931640f67cab3e3a16F4dC">USDT</option>
                              </select>
                            </div>
                          </div>
                          <div className="col">
                            <input
                              type="text"
                              onChange={(evnt) =>
                                handleCollateralChange(index, evnt)
                              }
                              value={token_value}
                              name="token_value"
                              className="form-control"
                              placeholder="Amount of Collateral"
                            />
                          </div>

                          <div className="col">
                            {inputFields.length !== 1 ? (
                              <button
                                style={{
                                  marginLeft: "-85%",
                                  paddingLeft: "10px",
                                  width: "80px",
                                  borderRadius: "10px",
                                }}
                                type="button"
                                className="btn"
                                onClick={removeInputFields}
                              >
                                Delete
                              </button>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      );
                    })}

                    <div className="row">
                      <div className="col-sm-12">
                        <button className="btn" type="button" onClick={addInputField}>
                          Add New
                        </button>
                      </div>
                    </div>
                    <div className="spacer-10"></div>
                    <div className="spacer-10"></div>
                    <div className="spacer-10"></div>
                    <h4>Custom Collateral Added</h4>
                    {customInputFields.map((data, index) => {
                      const { custom_token_id, custom_token_value } = data;
                      return (
                        <div
                          className="row my-3"
                          style={{ width: "150%" }}
                          key={index}
                        >
                          <div className="col">
                            <div className="form-group">
                              <input
                                type="text"
                                onChange={(evnt) =>
                                  handleCustomCollateralChange(index, evnt)
                                }
                                value={custom_token_id}
                                name="custom_token_id"
                                className="form-control"
                                placeholder="Enter custom token address"
                              />
                            </div>
                          </div>
                          <div className="col">
                            <input
                              type="text"
                              onChange={(evnt) =>
                                handleCustomCollateralChange(index, evnt)
                              }
                              value={custom_token_value}
                              name="custom_token_value"
                              className="form-control"
                              placeholder="Amount of Collateral"
                            />
                          </div>

                          <div className="col">
                            {customInputFields.length !== 1 ? (
                              <button
                                style={{
                                  marginLeft: "-85%",
                                  paddingLeft: "10px",
                                  width: "80px",
                                  borderRadius: "10px",
                                }}
                                className="btn"
                                type="button"
                                onClick={removeCustomInputFields}
                              >
                                Delete
                              </button>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      );
                    })}

                    <div className="row">
                      <div className="col-sm-12">
                        <button
                          className="btn"
                          type="button"
                          style={{ borderRadius: "10px" }}
                          onClick={addCustomInputField}
                        >
                          Add New
                        </button>
                      </div>
                    </div>

                    <div className="spacer-10"></div>
                    <div className="spacer-10"></div>
                  </div>
                </div>
                <br></br> <br></br> <br></br>
                <div
                  style={{ backgroundColor: "#142336"}}
                >
                  <div
                    style={{
                      paddingLeft: "10%",
                      paddingRight: "10%",
                      textAlign: "center",
                    }}
                  >
                    <div className="spacer-10"></div>
                    <div className="spacer-10"></div>
                    <h3>Timelock</h3>
                    <div
                      className="small-border"
                      style={{ alignItems: "left" }}
                    ></div>

                    <div className="spacer-10"></div>

                    <h5>
                      Timelock (Set Duration for Collateral Lock)
                    </h5>
                    <br></br>
                    <DatePicker
                      width="50px"
                      selected={lockDate}
                      onChange={(date) => setLockDate(date)}
                    />
                    <div className="spacer-10"></div>
                    <div className="spacer-10"></div>
                  </div>
                </div>
                <div style={{ textAlign: "center" }}>
                  <br></br>
                  <br></br>
                  <p>
                    Please note:
                    <br></br>
                    <br></br>If you time lock your assets, your collateral is
                    locked into a wNFT vault contract once deployed. This action
                    cannot be undone. A 5% Unwrap Fee will apply upon redeeming
                    the underlying assets from the Wrapped NFT.
                    <br></br>
                    <br></br>Secondary Market Royalties are fixed at 10%.
                    <br></br>- 7.5% of sale is added into the collateral vault.
                    <br></br>- 2.5% of sale is sent to Peak DAO treasury.
                    <br></br>
                    <br></br>Unwrap fee is 5%:
                    <br></br>- 2.5% to Wrapping Contract.
                    <br></br>- 2.5% to Peak DAO treasury.
                  </p>
                  <br></br>
                  <button type="submit" className="btn-main">
                    {!isActive && (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )}
                    {" " + submitStatus}{" "}
                  </button>
                  <p className="text-danger">{errorStatus}</p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}
