import React, { useEffect, useState } from "react";
import { Tabs, Tab, Table } from "react-bootstrap";
import Footer from "../components/footer";
import Clock from "../components/Clock";
import Collateral from "../components/Collateral";

import { createGlobalStyle } from "styled-components";
import { useParams } from "react-router-dom";
import { useAccount, useContractReads, useNetwork } from "wagmi";
import { ApolloClient, InMemoryCache, gql } from "@apollo/client";
import WrapperBaseAbi from "../../contractAbi/WrapperBaseAbi.json";
import "dotenv/config";
import { GRAPH_URL, WRAPPER_CONTRACT_ADDRESS } from "../config";

const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.sticky.white {
    background: #fff;
    border-bottom: solid 1px #fff;
  }
  header#myHeader.navbar .search #quick_search{
    color: #fff;
    background: rgba(255, 255, 255, .1);
  }
  header#myHeader.navbar.white .btn, .navbar.white a, .navbar.sticky.white a{
    color: #fff;
  }
  header#myHeader .dropdown-toggle::after{
    color: rgba(255, 255, 255, .5);
  }
  header#myHeader .logo .d-block{
    display: none !important;
  }
  header#myHeader .logo .d-none{
    display: block !important;
  }
  @media only screen and (max-width: 1199px) {
    .navbar{
      background: #fff;
    }
    .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
      background: #fff;
    }
    .item-dropdown .dropdown a{
      color: #fff !important;
    }
  }
`;

const tokensQuery = `
    query($id: String, $id1: String) {
      ownerships(where: {id_in: [$id, $id1]}) {
        id
        type
        owner
        amount
        tokenId
        unlockTime
        inAsset
        inTokenId
      }
    }
`;

export default function Detail() {
  const { contract, id } = useParams();
  const { chain, chains } = useNetwork();
  const { address, connector: activeConnector, isConnected } = useAccount();
  const [nftItem, setNftItem] = useState(null);
  const [metadata, setMetadata] = useState(null);

  const client = new ApolloClient({
    uri: GRAPH_URL[chain ? chain.id : 5],
    cache: new InMemoryCache(),
  });

  const wrapperBase = {
    address: WRAPPER_CONTRACT_ADDRESS[chain ? chain.id : 5],
    abi: WrapperBaseAbi,
  };

  const { data, isError, isLoading } = useContractReads({
    contracts: [
      {
        ...wrapperBase,
        functionName: "getWrappedToken",
        args: [contract, id],
      },
      {
        ...wrapperBase,
        functionName: "getOriginalURI",
        args: [contract, id],
      },
    ],
  });
  useEffect(() => {
    async function fetchData() {
      const res = await client.query({
        query: gql(tokensQuery),
        variables: {
          id: contract.toLowerCase() + id.toLowerCase(),
          id1:
            contract.toLowerCase() + id.toLowerCase() + address.toLowerCase(),
        },
      });
      if (res.data && res.data.ownerships) {
        if (res.data.ownerships.length > 0) {
          setNftItem(res.data.ownerships[0]);
        }
      }
      if (data && data.length > 1) {
        try {
          const response = await fetch(data[1]);
          const resp = await response.text();
          const json = JSON.parse(resp);
          setMetadata(json);
        } catch (error) {
          console.log(error);
        }
      }
    }
    fetchData();
  }, [contract, id]);
  return (
    <div>
      <GlobalStyles />
      {nftItem != null && (
        <div key={nftItem.id}>
          <section
            className="jumbotron breadcumb no-bg"
            style={{
              backgroundImage: `url(${"./img/background/subheader.jpg"})`,
            }}
          >
            <div className="mainbreadcumb">
              <div className="container">
                <div className="row m-10-hor">
                  <div className="col-12 text-center">
                    <h1>Wrapped NFT Detail Page</h1>
                    <div className="de_countdown_detail">
                      <p> Time left for unwrap </p>{" "}
                      <Clock deadline={nftItem.unlockTime} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="container">
            <div className="row">
              <div className="col-md-12">
                <h5>Wrapped NFT Type : {nftItem.type}</h5>
                <h5>Wrapped NFT Token ID : {nftItem.tokenId}</h5>
              </div>
              <div className="col-md-6">
                <br />
                {metadata != null && (
                  <div>
                    <p>
                      <img
                        src={metadata.image}
                        alt="Image Alt"
                        loading="lazy"
                        width="90%"
                      />
                    </p>
                  </div>
                )}
                <br />
              </div>
              <div className="col-md-6">
                <br />
                <div>
                  <p>
                    Token Address :{" "}
                    {nftItem.inAsset.slice(0, 8) +
                      "..." +
                      nftItem.inAsset.slice(38, 42)}
                    <br />
                    Token ID : {nftItem.inTokenId}
                    <br/>
                    <br/>
                  </p>
                  {metadata != null && metadata != undefined &&
                    Object.values(metadata).map((item, index) => {
                      const keys = Object.keys(metadata);
                      const keyword = keys[index];
                      if (keyword != "image" && !keyword.includes("_") && keyword != "attributes") {
                          return (
                            <p key={index + 2}>
                                {keyword[0].toUpperCase() + keyword.slice(1)} : {item}
                              <br />
                            </p>
                          );
                        }
                    })}
                    <br/>
                    <h5>NFT Attributes</h5> 
                    {metadata != null && metadata != undefined && "attributes" in metadata &&
                      Object.values(metadata["attributes"]).map((item, index) => {
                          const attribute = Object.values(item);
                          return (
                            <p key={index + 2}>
                                {attribute[0][0].toUpperCase() + attribute[0].slice(1)} : {attribute[1]}
                              <br />
                            </p>
                          );
                    })}
                </div>
                <br />
              </div>
              <div className="col-md-12">
                <div>
                  <h5>Collaterals</h5>
                  {data && data.length > 0 && data[0].collateral.length > 0 && (
                    <Collateral collateral={data[0].collateral} />
                  )}
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
      <Footer />
    </div>
  );
}
